// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("popper.js")
require("bootstrap");

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import "channels"

import "controllers"
import 'bootstrap-icons/font/bootstrap-icons.css'
// import "../stylesheets/application"
import "src"