import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"];

    search() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.formTarget.submit();
        }, 300);
    }

    setCurrentProduct(event) {
        const productCardsContainer = event.currentTarget.closest('.ProductCards');

        if (productCardsContainer) {
            const productCards = productCardsContainer.querySelectorAll('.ProductCard');

            productCards.forEach(card => card.classList.remove('ProductCard--Active'));

            event.currentTarget.classList.add('ProductCard--Active');
        }
    }
}
